<template>
  <div
    :class="[
      'tools',
      collapsed && 'is--collapsed'
    ]"
  >
    <div class="tools__bar">
      <meeting-tools-control
        class="tools__menu"
        ref="menu"
        :submenu="submenu"
        @submenu="submenuHandler"
        @delete="deleteHandler"
        @shape="shapeHandler"
        @task="taskHandler"
        @saveSnapshot="saveSnapshotHandler"
        @takeSnapshot="takeSnapshotHandler"
        @uploadImage="uploadImageHandler"
        @screensharing="screensharingHandler"
        @recording="recordingHandler"
        @ocr="ocrHandler"
        @torch="torchHandler"
        @chat="chatHandler"
        @settings="settingsHandler"
      />
      <!-- MORE -->
      <div class="tools__more">
        <meeting-tools-popper
          v-if="menuOverflow"
          :content="$t('tools.moreTools')"
        >
          <a-button
            variant="link-primary"
            @click="submenuHandler"
            value="more"
          >
            <a-icon name="more-h" />
          </a-button>
        </meeting-tools-popper>
      </div>
      <!-- SUBMENU -->
      <meeting-tools-control
        :class="[
          'tools__submenu',
          `tools__submenu--${menuCount}`,
          submenu === 'more' && 'is--visible'
        ]"
        :submenu="submenu"
        @submenu="submenuHandler"
        @delete="deleteHandler"
        @shape="shapeHandler"
        @task="taskHandler"
        @saveSnapshot="saveSnapshotHandler"
        @takeSnapshot="takeSnapshotHandler"
        @uploadImage="uploadImageHandler"
        @screensharing="screensharingHandler"
        @recording="recordingHandler"
        @ocr="ocrHandler"
        @torch="torchHandler"
        @chat="chatHandler"
        @settings="settingsHandler"
      />
      <template v-if="user.isAssistant && app.state.matches('app.view.meeting.draw')">
        <div
          :class="[
            'tools__submenu',
            submenu === 'size' && 'is--visible'
          ]"
          :ref="(el) => { refs['size'] = el}"
        >
          <a-button
            v-for="size in board.sizes"
            :key="size"
            variant="link-default"
            :class="board.size === size && 'is--active'"
            :value="size"
            @click="sizeHandler"
          >
            <a-icon
              name="circle-full"
              :scale="size / 24"
            />
          </a-button>
        </div>
        <div
          :class="[
            'tools__submenu',
            submenu === 'color' && 'is--visible'
          ]"
          :ref="(el) => { refs['color'] = el}"
        >
          <a-button
            v-for="color in board.colors"
            :key="color"
            variant="link-default"
            :class="board.color === color && 'is--active'"
            :style="{ color }"
            :value="color"
            @click="colorHandler"
          >
            <a-icon name="rect-full" />
          </a-button>
        </div>
      </template>
      <div class="tools__pinned">
        <template v-if="user.isAssistant && app.state.matches('app.view.meeting.draw')">

          <div class="dropdown">
            <!-- Button handler  -->
            <a-button
              variant="primary"
              class="dropdown-button dropdown-button-text"
              @click="preHandlerSnapshot"
            >
              <span v-if="!(isMobile && app.state.matches('app.view.meeting.draw.default')) && !app.state.matches('app.view.meeting.draw.select')" class="me--2">{{ selectedOption?.label }}</span>
              <span> <a-icon :name="selectedOption?.icon" /></span>
            </a-button>
            <!-- Button toggle  -->
            <a-button
              v-if="app.state.matches('app.view.meeting.draw.default')"
              variant="primary"
              class="dropdown-button-icon"
              @click="toggleDropdown"
            >
              <span>▼</span>
            </a-button>
            <!-- Dropdown menu -->
            <ul v-if="isDropdownOpen" class="dropdown-menu">
              <li
                v-for="option in options"
                :key="option"
                @click="selectOptionHandler(option)"
                class="dropdown-item"
              >
                {{ option?.label }}
              </li>
            </ul>
            <a-button
              v-else-if="app.state.matches('app.view.meeting.draw.select')"
              variant="primary"
              label
              @click="confirmHandler"
            >
              <span class="me--2">{{ $t('tools.confirm') }}</span>
              <a-icon name="check" />
            </a-button>
          </div>
        </template>
        <a-button
          v-else
          variant="danger"
          label
          @click="stopCallHandler"
        >
          <span class="me--2">{{ $t('tools.endSession') }}</span>
          <a-icon name="phone" />
        </a-button>
      </div>
      <a-spacer />
    </div>
    <Transition name="fade">
      <div
        v-if="submenu ||   isDropdownOpen"
        class="tools__backdrop"
        @click="backdropHandler"
      >
      </div>
    </Transition>
    <div
      :class="[
        'tools__toggle',
        app.isHmtDevice && 'opacity--0'
      ]"
    >
      <a-button
        variant="link-primary"
        :data-wml-speech-command="$t(`commands.${collapsed ? 'show' : 'hide'}Toolbar`)"
        @click="toggleHandler"
      >
        <a-icon name="expand" />      
      </a-button>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import container from '@di'
import {
  useUserStore,
  useAppStore,
  useBoardStore,
  useExitStore,
  useNotificationStore
} from '@/stores'
import { useMeetingMachine } from '@/state'
import { createPopper } from '@popperjs/core'
import { useElementWidth } from '@/composables'
import { repository } from '@communication/messages'
import { roundDecimals } from '@/utils'
import { useTranslation } from 'i18next-vue'
import { useMediaQuery } from '@vueuse/core'

const user = useUserStore()
const app = useAppStore()
const board = useBoardStore()
const exit = useExitStore()
const notification = useNotificationStore()
const { t } = useTranslation()

const isMobile = useMediaQuery('(max-width: 1086px)')

const isDropdownOpen = ref(false)
const options = [
  {
    icon: 'save',
    label:  t('tools.saveAndClose'),
  },
  {
    icon: 'close',
    label:  t('tools.close'),
  }
]
let selectedOption = {
  icon: 'save',
  label:  t('tools.saveAndClose'),
}
const { state, send } = useMeetingMachine()

const menu = ref(null)
const submenu = ref(null)
const typeSaveMedia = ref('')
const collapsed = ref(true)


const refs = {}
let popperEl = null

const buttonSize = 49.6

const { offsetWidth, scrollWidth } = useElementWidth(menu, { offset: 8 })

const menuCount = computed(() => Math.floor(offsetWidth.value / buttonSize))

const menuOverflow = computed(() => scrollWidth.value > Math.round(offsetWidth.value))

const menuMoreOffset = computed(() => `-${roundDecimals(offsetWidth.value - (menuCount.value * buttonSize), 1)}px`)

function destroySubmenu () {
  submenu.value = null
  if (popperEl) {
    popperEl.destroy()
    popperEl = null
  }
}

function toggleDropdown() {
  isDropdownOpen.value = !isDropdownOpen.value
}

function selectOptionHandler(option) {
  selectedOption = option
  isDropdownOpen.value = false
  preHandlerSnapshot()
}

function stopCallHandler () {
  const isScreenSharing = app.state.matches('app.view.meeting.screensharing')
  if (isScreenSharing) {
    container.procedures.get('screensharingProcedure').stop()
  }
  if (user.isAssistant) {
    //  TODO: const response = await container.repository.fetchEvents('location')
    container.jsonrpc.send(repository.fetchEventsMessage('location'), (error, response) => {
      if (!error) {
        const filtered = JSON.parse(response.value).filter((item) => item.data.participant.party === 'client')
        if (filtered.length) {
          const { latitude, longitude, source } = filtered[0].data.location
          const coords = [latitude, longitude]
          if (coords.every((value) => typeof value === 'number')) {
            exit.setLocation(coords, source)
          }
        }
      }
      container.procedures.get('destroyProcedure').destroy()
    })
  } else {
    container.procedures.get('destroyProcedure').destroy()
  }
}

function takeSnapshotHandler () {
  typeSaveMedia.value = 'snapshot';
  container.procedures.get('snapshotProcedure').take()
}

function dismissSnapshotHandler () {
  container.messenger.emit('board:signal', 'snapshot:save')
  container.procedures.get('snapshotProcedure').dismiss(typeSaveMedia.value)
}

function closeSnapshotHandler () {
  container.procedures.get('snapshotProcedure').dismiss()
}

function uploadImageHandler () {
  typeSaveMedia.value = 'image';
  container.messenger.emit('droparea:signal', 'image:upload')
}

function torchHandler () {
  container.procedures.get('torchProcedure').toggle()
}

function deleteHandler () {
  container.messenger.emit('board:signal', 'object:remove')
}

function confirmHandler () {
  container.messenger.emit('board:signal', 'object:discard')
}

function screensharingHandler () {
  const isScreensharing = app.state.matches('app.view.meeting.screensharing')
  if (isScreensharing) {
    container.procedures.get('screensharingProcedure').stop()
  } else {
    container.procedures.get('screensharingProcedure').start()
  }
}

function recordingHandler () {
  const isRecording = app.state.matches('app.recording.on')
  if (isRecording) {
    container.procedures.get('recordingProcedure').stop()
  } else {
    container.procedures.get('recordingProcedure').start()
  }
}

function ocrHandler () {
  container.messenger.emit('board:signal', `ocr:${board.ocr ? 'discard' : 'recognize'}`)
}

function saveSnapshotHandler () {
  container.messenger.emit('board:signal', 'snapshot:save')
  notification.showMessage({
    text: t('notification.snapshotSaved'),
    icon: 'save',
    auto: true
  })
}

function chatHandler () {
  state.value.matches('chat.visible') ? send('HIDE_CHAT') : send('SHOW_CHAT')
}

function settingsHandler () {
  app.transition('SHOW_DEVICES_MODAL')
}

function submenuHandler (event) {
  const { target } = event
  const { value } = target
  if (submenu.value === value) {
    destroySubmenu()
  } else {
    popperEl = createPopper(target, refs[value], {
      placement: target.dataset.placement || 'top',
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 8]
          }
        }, {
          name: 'flip',
          options: {
            fallbackPlacements: ['top-start', 'top', 'top-end']
          }
        },
        {
          name: 'preventOverflow',
          options: {
            padding: {
              left: 20,
              right: 20
            },
          },
        }
      ]
    })
    submenu.value = value
  }
}

function taskHandler (event) {
  const { value } = event.target
  board.setTask(value)
}

function shapeHandler (event) {
  const { value } = event.target
  const taskMap = {
    freedraw: null,
    text: 'typing'
  }
  board.setTask(value in taskMap ? taskMap[value] : 'shaping')
  board.setShape(value)
}

function sizeHandler (event) {
  const { value } = event.target
  board.setSize(parseInt(value))
  destroySubmenu()
}

function colorHandler (event) {
  const { value } = event.target
  board.setColor(value)
  destroySubmenu()
}

function backdropHandler () {
  destroySubmenu()
  isDropdownOpen.value = false
}

function toggleHandler () {
  collapsed.value = !collapsed.value
}

function preHandlerSnapshot () {
  if (selectedOption.label === t('tools.close')) {
    closeSnapshotHandler()
  } else {
    dismissSnapshotHandler()
  }
}
</script>

<style lang="scss">
$tools-bg: $white;
$tools-active-bg: $purple-200;
$tools-menu-spacer: 0.25rem;
$tools-count: 15;

/* Dropdown container */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown button */
.dropdown-button {
  min-width: 42px;
  background-color: #3E31C3;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.dropdown-button-text {
  border-radius: 5px 0 0 5px !important;
  border-right: 0.5px solid white;

  &:focus {
    border-right: 0.5px solid white !important;
  }

  &:hover {
    color:white;
    background-color: #2E3191;
    border-color: white;
  }
}

.dropdown-button-icon {
  color: white;
  padding: 0;
  span {
    min-width: 30px;
  }
  min-width: 42px;
  background-color: #3E31C3;
  border-radius: 0 5px 5px 0 !important;

  &:hover,
  &:focus {
    color: white !important;
  }
}


/* Dropdown menu */
.dropdown-menu {
  position: absolute;
  top: -155%;
  left: 0;
  width: 126%;
  margin: 0;
  padding: 0;
  list-style: none;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  overflow: visible;
}

/* Dropdown items */
.dropdown-item {
  padding: 5px;
  cursor: pointer;
  text-align: start;
  font-size: 14px;
}

/* Dropdown item hover effect */
.dropdown-item:hover {
  background-color: #f0f0f0;
}

.tools {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: $zindex-tools;
  &__bar {
    display: grid;
    grid-template-columns: auto 1fr auto;
    grid-template-rows: auto;
    padding: ($layout-spacer-sm * 0.5) $box-spacer;
    background-color: $tools-bg;
    .is--active {
      background-color: $tools-active-bg;
    }
    .is--selected {
      position: relative;
      background-color: $tools-bg;
      z-index: 2;
    }
  }
  &__menu,
  &__submenu,
  &__more {
    .btn {
      margin: 0 $tools-menu-spacer;
    }
  }
  &__menu {
    display: flex;
    max-width: 100%;
    overflow: hidden;
  }
  &__more {
    transform: translateX(v-bind(menuMoreOffset));
    pointer-events: none;
    .btn {
      background-color: $tools-bg;
      pointer-events: auto;
    }
  }
  &__submenu {
    position: absolute;
    max-width: calc(100vw - #{$box-spacer * 2});
    display: flex;
    flex-wrap: wrap;
    background-color: inherit;
    border-radius: $border-radius;
    padding: ($layout-spacer-sm * 0.5);
    z-index: 2;
    visibility: hidden;
    pointer-events: none;
    &.is--visible {
      visibility: visible;
      pointer-events: auto;
    }
    & > div.inline-block {
      display: none;
    }
    @for $i from 1 to $tools-count {
      &--#{$i} {
        > div:nth-child(#{$i}) ~ div.inline-block {
          display: inline-block !important;
        }
      }
    }
  }
  &__pinned {
    display: flex;
    justify-content: end;
    column-gap: 5px;
    text-align: right;
  }
  &__backdrop {
    position: fixed;
    left: 0; top: 0; right: 0; bottom: 0;
    background-color: $backdrop;
    z-index: 1;
  }
  &__toggle {
    display: none;
  }
  @media (min-width: $breakpoint-md) {
    &__bar {
      grid-template-columns: 1fr auto auto 1fr;
    }
    &__menu {
      grid-column-start: 2;
    }
  }
  @media (max-height: ($breakpoint-sm + 1)) {
    transition-property: transform;
    transition-duration: $duration-fast;
    &.is--collapsed {
      transform: translateY(100%);
    }
    &__toggle {
      display: block;
      position: absolute;
      left: $box-spacer;
      transform: translateY(1px);
      bottom: 100%;
      .btn {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        background-color: $tools-bg;
      }
      .icon {
        transition-property: transform;
        transition-duration: $duration-fast;
        .is--collapsed & {
          transform: rotate(180deg);
        }
      }
    }
  }
}
</style>
